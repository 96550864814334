import { baseTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { FarmConfigV3 } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedFarms: FarmConfigV3[] = [
  {
    pid: 10,
    lpAddress: '0x72AB388E2E2F6FaceF59E3C3FA2C4E29011c2D38',
    token0: baseTokens.weth,
    token1: baseTokens.usdc,
    feeAmount: FeeAmount.LOWEST,
  },
  {
    pid: 5,
    lpAddress: '0xB775272E537cc670C65DC852908aD47015244EaF',
    token0: baseTokens.weth,
    token1: baseTokens.usdc,
    feeAmount: FeeAmount.LOW,
  },
]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedFarms,

  {
    pid: 4,
    lpAddress: '0x9BFa331679b307Cf358438F45f6413a205FD3EBf',
    token0: baseTokens.weth,
    token1: baseTokens.dai,
    feeAmount: FeeAmount.LOW,
  },
])
