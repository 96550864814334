import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | 9mm Dex',
  defaultTitle: '9mm Dex',
  description: 'Trade, earn, and own crypto on the all-in-one multichain DEX',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@9mm_pro',
    site: '@9mm_pro',
  },
  openGraph: {
    title: "9mm Dex - Everyone's Favorite DEX",
    description: 'Trade, earn, and own crypto on the all-in-one multichain DEX',
    images: [{ url: 'https://pbs.twimg.com/profile_banners/1658474887596621824/1692616164/1500x500' }],
  },
}
