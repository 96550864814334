import { bscTokens } from '@pancakeswap/tokens'
import { getAddress } from 'viem'

import { PoolCategory, SerializedPool } from '../../types'

export const livePools: SerializedPool[] = [
  {
    sousId: 0,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.cake,
    contractAddress: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
  },
  {
    sousId: 379,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.solvbtc,
    contractAddress: '0x782538DFa37fB8c5d04c44C14B9352743FB15f9f',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.000000096',
    isFinished: false,
  },
  {
    sousId: 378,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pepe,
    contractAddress: '0x834c32067BF90A1c27fA1a2511a9126f20BAeD27',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3157.1',
    version: 3,
  },
  {
    sousId: 377,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pepe,
    contractAddress: '0x625079cE28FaBe21F032083426288a82a69a2344',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1407',
    version: 3,
  },
  {
    sousId: 376,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.octavia,
    contractAddress: '0x0043bc9b395245B63eA548Bb11c7d2609Bf7B327',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01157',
    version: 3,
  },
  {
    sousId: 375,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.grape,
    contractAddress: '0xf145104924e0993542A6c86C95575fFf81F7c932',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.6172',
    version: 3,
  },
  {
    sousId: 374,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.chat,
    contractAddress: '0xe2471BBAcF78e394435De5A5c7ded735ca6d4692',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.007716',
    version: 3,
  },
  {
    sousId: 373,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.aitech,
    contractAddress: '0xa09F3F97CF36C35465A9117e4F70758Dcd905F91',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1157',
    version: 3,
  },
  {
    sousId: 372,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xrgb,
    contractAddress: '0x9d5a02246dE31cfd120aB18694309f0363906677',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.05787',
    version: 3,
  },
  {
    sousId: 371,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.csix,
    contractAddress: '0xD41794253c07Fe3097721f98E985ecB7f692362e',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.07716',
    version: 3,
  },
  {
    sousId: 370,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.defi,
    contractAddress: '0x887b8E18cb4aE23A4db11B365cc20fD0Cf00FEe0',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01736',
    version: 3,
  },
  {
    sousId: 369,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.gtai,
    contractAddress: '0x2C52E3008E0506c9DD98492af49f31733a0a1AC9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0027',
    version: 3,
  },
  {
    sousId: 368,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.cgpt,
    contractAddress: '0x55c8BcEc0df2A61B6eF24815B3462293A27366a2',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.038',
    version: 3,
  },
  {
    sousId: 366,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ckp,
    contractAddress: '0x87f0210c658c81e854e6022315cD68804944acaE',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.001929',
    version: 3,
  },
].map((p) => ({
  ...p,
  contractAddress: getAddress(p.contractAddress),
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [
  {
    sousId: 367,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.irl,
    contractAddress: '0x41cD0Fad28F8531De22617959bc943F1B3E12Bd8',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0694444444444445',
    version: 3,
  },
  {
    sousId: 365,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ace,
    contractAddress: '0xafB6d6B64fe5007EeE87210B91638ddCeb9f326B',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.003858',
    isFinished: false,
    version: 3,
  },
  {
    sousId: 364,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.csix,
    contractAddress: '0x692dF8297495f02f31a24A93D10Bd77D072840d7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5131',
    version: 3,
  },
  {
    sousId: 363,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.play,
    contractAddress: '0x17D87e825A2231628bCBf1aF1aaEaD419dd3a53D',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5902',
    version: 3,
  },
  {
    sousId: 362,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xcad,
    contractAddress: '0x1328F684569cE0Cb0F13ce023e1C910f23CaBcA8',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01658',
    version: 3,
  },
  {
    sousId: 361,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.mbx,
    contractAddress: '0x51ebC2F8D7Af302835A1C2Bd02111a3af573e399',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1118',
    version: 3,
  },
  {
    sousId: 359,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.wncg,
    contractAddress: '0xBA75a11EC6e8AFcDAb95E46F5E39941d6bB7334F',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.7716',
    version: 3,
  },
  {
    sousId: 347,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.tusd,
    contractAddress: '0x72cd910eE115E494485Dd32Ce7bC5dE563eceA51',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.02314',
    version: 3,
  },
  {
    sousId: 360,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.axl,
    contractAddress: '0x32f0414a4e970a3edd9e7db367A43348F25D74cD',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.05358',
    version: 3,
  },
  {
    sousId: 357,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ush,
    contractAddress: '0x3408367d6d4F379e1dfb2bb61664c4833Ec3588c',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1157',
    version: 3,
  },
  {
    sousId: 356,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dck,
    contractAddress: '0x3171DeA40dB52081999Cd589972EF55D5D5B4777',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5594',
    version: 3,
  },
  {
    sousId: 352,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sable,
    contractAddress: '0x40757a3F2BC322f411D905cD489eaf4Eb84C50Aa',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.192',
    version: 3,
  },
  {
    sousId: 351,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.eqb,
    contractAddress: '0x283812B4F4c77A0f169b6F8a79d1d7d996770477',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.02411',
    version: 3,
  },
  {
    sousId: 354,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.snbnb,
    contractAddress: '0x854906693F7bAc4E4c8C31c001b6a42b3d93493E',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00004813',
    version: 3,
  },
  {
    sousId: 350,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xalgo,
    contractAddress: '0x0Cb99886e31FCe86Fc411A2D744d63E6748133b5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.05865',
    version: 3,
  },
  {
    sousId: 349,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pendle,
    contractAddress: '0x658136728Cf2D0845BEE552B07cA7FE82D1B7938',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01426',
    version: 3,
  },
  {
    sousId: 348,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xalgo,
    contractAddress: '0x4b5A6BA2cAf4B6FD04407513172D3e01c288f9bD',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.07716',
    version: 3,
  },
  {
    sousId: 353,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hay,
    contractAddress: '0x8c41046b3C0D7b7C80316a57C39C74c9F5133852',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.02314',
    version: 3,
  },
  {
    sousId: 346,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.csix,
    contractAddress: '0x5250320d765F366E2B96Cd5c7d08F1902422195e',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.017',
  },
  {
    sousId: 355,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.planet,
    contractAddress: '0x69Aeec4EBA2333deC563E378a6AFD5eF23437677',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '150.4',
    version: 3,
  },
  {
    sousId: 325,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.lvl,
    contractAddress: '0x1394a09F868bE27B1c8D39D79F0b0D6f112bddAf',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.009765',
    version: 3,
  },
  {
    sousId: 345,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.axl,
    contractAddress: '0x0592c701fE5DE53d534AFBaf3A11A8F1bbEE9E91',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.05173',
    version: 3,
  },
  {
    sousId: 343,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.edu,
    contractAddress: '0x3d2d34Ea77B3702B7634C8D208feC5E08CEa88B6',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.05425',
    version: 3,
  },
  {
    sousId: 342,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ush,
    contractAddress: '0x6Db79ba7c0A6DDC48cFDd79Df6cb757b9E8B51DD',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1851',
    version: 3,
  },
  {
    sousId: 341,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pstake,
    contractAddress: '0x17086aF09D63852aD4646f1f837b34e171bEa99B',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5497',
    version: 3,
  },
  {
    sousId: 327,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.id,
    contractAddress: '0x7aCcC054bB199ca976C95aee495C9888f566AaAA',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.07716',
    version: 3,
  },
  {
    sousId: 326,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.unw,
    contractAddress: '0x929641DF8F11b6460efAdb09db357717C60003E1',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.7716',
    version: 3,
  },
  {
    sousId: 329,
    stakingToken: bscTokens.hay,
    earningToken: bscTokens.cake,
    contractAddress: '0x1c7D573D9614187096276a01Ec15263FCa820BDD',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0121',
    version: 3,
  },
  {
    sousId: 324,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.caps,
    contractAddress: '0xA31a351e3FBE3278949242Ff152317c12cd786e2',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.7',
    version: 3,
  },
  {
    sousId: 321,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.csix,
    contractAddress: '0x8BD7b0d392D2dE8F682704A3186A48467FcDC7AC',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '8.68',
    version: 3,
  },
  {
    sousId: 323,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sd,
    contractAddress: '0xaEC63F134a7853C6DaC9BA428d7962cD7C6c5e30',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01022',
    version: 3,
  },
  {
    sousId: 328,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.rdnt,
    contractAddress: '0xb87d170eC2C22F6078C9ed3214aB6f47f4A924D2',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.3342',
    version: 3,
  },
  {
    sousId: 339,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.axl,
    contractAddress: '0xC0878B7907De5d332C6C296E30d14d604AA6ada6',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01041',
    version: 3,
  },
  {
    sousId: 320,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.axlusdc,
    contractAddress: '0x08287F4942A7B68DDb87D20Becd4fdadF4aE206e',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0135',
    version: 3,
  },
  {
    sousId: 344,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.peel,
    contractAddress: '0xeBc4E95DF515a34c173530b231EDa53E6a786944',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.9186',
    version: 3,
  },
  {
    sousId: 322,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pstake,
    contractAddress: '0x98AC153577d65f2eEF2256f3AeF8ba9D7E4B756B',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1186',
    version: 3,
  },
  {
    sousId: 340,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.champ,
    contractAddress: '0x731Aa0b17143A3095430bf322D6aB132cA32b99F',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.432',
    version: 3,
  },
  {
    sousId: 336,
    stakingToken: bscTokens.xcad,
    earningToken: bscTokens.cake,
    contractAddress: '0x548e422031E9063c21c84C7478EBa0f7ae9641B7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.009548',
    version: 3,
  },
  {
    sousId: 338,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sfund,
    contractAddress: '0x4809d86700E1f6be32992172Bd57fD3d954993e7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.06145',
    version: 3,
  },
  {
    sousId: 337,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.moni,
    contractAddress: '0x47EF9D0A1ff91b4398Ff622eEaf111874d2e0870',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.366',
    version: 3,
  },
  {
    sousId: 335,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sis,
    contractAddress: '0xFBA59bA5485670ec243EFA8903564aa5C0AD2373',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5704',
    version: 3,
  },
  {
    sousId: 334,
    stakingToken: bscTokens.rdnt,
    earningToken: bscTokens.cake,
    contractAddress: '0xaaFf0B9fdC503087764Ee7155039015d74fE79B1',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.009409',
    version: 3,
  },
  {
    sousId: 333,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.gq,
    contractAddress: '0x2F50D0010d408e0C299BE8e1a8d553B8eb3E96ED',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '7.093',
    version: 3,
  },
  {
    sousId: 306,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.squad,
    contractAddress: '0x08C9d626a2F0CC1ed9BD07eBEdeF8929F45B83d3',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.459',
    version: 3,
  },
  {
    sousId: 332,
    stakingToken: bscTokens.csix,
    earningToken: bscTokens.cake,
    contractAddress: '0x6fBD8a65c844a3565cA4e71Eb577a2a8F821ABB4',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0124',
    version: 3,
  },
  {
    sousId: 331,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.mgp,
    contractAddress: '0xCA4A503c79cfDa91246f2084fE315F1cA54694D8',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '5.704',
    version: 3,
  },
  {
    sousId: 330,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.deprecated_8pay,
    contractAddress: '0x67c7014b1C6B6a2d811687d4c5a53de518fA705f',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.48',
    version: 3,
  },
  {
    sousId: 310,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.champ,
    contractAddress: '0x3B48325b7CA831ca7D5b649B074fF697c66166c3',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.961',
    version: 3,
  },
  {
    sousId: 303,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.mgp,
    contractAddress: '0x365F744c8b7608253697cA2Ed561537B65a3438B',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '6.944',
    version: 3,
  },
  {
    sousId: 309,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.arena,
    contractAddress: '0xDe9FC6485b5f4A1905d8011fcd201EB78CF34073',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.893',
    version: 3,
  },
  {
    sousId: 307,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.zbc,
    contractAddress: '0xa683C30d47BCd31fB1399b80A4475bc960b903e3',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10.52',
    version: 3,
  },
  {
    sousId: 308,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.primal,
    contractAddress: '0x7cE7A5C3241629763899474500D8db1fDFf1dab6',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '30.86',
    version: 3,
  },
  {
    sousId: 304,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xcad,
    contractAddress: '0x68Cc90351a79A4c10078FE021bE430b7a12aaA09',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1102',
    version: 3,
  },
  {
    sousId: 302,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.wmx,
    contractAddress: '0xaEd58Af2c98dbF05d26B3E5b3cc809fc70D203ce',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5787',
    version: 3,
  },
  {
    sousId: 305,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hft,
    contractAddress: '0x92465602f35bb0F22aA1Cf2102B17B563B0a26Dd',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.6319',
    version: 3,
  },
  {
    sousId: 301,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.krs,
    contractAddress: '0x067F926EB8f2a95f260327B01C29B2034A785029',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '4.96',
    version: 3,
  },
  {
    sousId: 299,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.co,
    contractAddress: '0x31736A52bBdda4eaf0117B17736306Bbecb711ca',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '4.6296',
    version: 3,
  },
  {
    sousId: 298,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hoop,
    contractAddress: '0x6C254E3AB3e4B909A2CfAA3E0b9d2B3F7887CFae',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.146',
    version: 3,
  },
  {
    sousId: 297,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.moni,
    contractAddress: '0xB5e36D441016016f251223EBD5E946cdeFe0E2E1',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.411',
    version: 3,
  },
  {
    sousId: 296,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.gq,
    contractAddress: '0x983A241Bfe910fcFa3E70744AA21AAe5f45861f0',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '55.6446',
    version: 3,
  },
  {
    sousId: 300,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.arv,
    contractAddress: '0x420Cd06440d8845FBcde9a396d9Aa211FE78C03d',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1335.47',
    version: 3,
  },
  {
    sousId: 295,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.wom,
    contractAddress: '0xf78DB5b635bA108212C9D84c124a52D7B1596b97',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3.4722',
    version: 3,
  },
  {
    sousId: 294,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ankr,
    contractAddress: '0x985A20998c7C59e98005c556393215e39c9A4978',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '6.0763',
    version: 3,
  },
  {
    sousId: 293,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hay,
    contractAddress: '0x8cBCf2f2be93D154be5135f465369Ee6dD84314B',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.2604',
    version: 3,
  },
  {
    sousId: 292,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sfund,
    contractAddress: '0x449EC6B2779A3F63E6c2527CBB162A38Ef199d7D',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1071',
    version: 3,
  },
  {
    sousId: 291,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pstake,
    contractAddress: '0x56D6955Ba6404647191DD7A5D65A5c9Fe43905e1',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.1574',
    version: 3,
  },
  {
    sousId: 290,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.peel,
    contractAddress: '0x288d1aD79c113552B618765B4986f7DE679367Da',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '4.34',
    version: 3,
  },
  {
    sousId: 289,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.shell,
    contractAddress: '0x595B7AF4F1828AB4953792482b01B2AFC4A46b72',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '89.699',
    version: 3,
  },
  {
    sousId: 288,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.high,
    contractAddress: '0x28cc814bE3B994187B7f8Bfed10516A84A671119',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1332',
    version: 3,
  },
  {
    sousId: 287,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ole,
    contractAddress: '0xda6F750be1331963E5772BEe757062f6bddcEA4C',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.2569',
    version: 3,
  },
  {
    sousId: 286,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.trivia,
    contractAddress: '0x86471019Bf3f403083390AC47643062e15B0256e',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '4.976',
    version: 3,
  },
  {
    sousId: 285,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sdao,
    contractAddress: '0x168eF2e470bfeAEB32BE52FB218A41483904851c',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.405',
    version: 3,
  },
  {
    sousId: 284,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.antex,
    contractAddress: '0xdC37A2B2A6a62008beEe029E36153dF8055a8ADa',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1937.62',
    version: 3,
  },
  {
    sousId: 283,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xcn,
    contractAddress: '0xa79D37ce9DF9443eF4B6DEC2e38a8ecd35303adc',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.2505',
    version: 3,
  },
  {
    sousId: 282,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.chr,
    contractAddress: '0x2D17ec6cd0AF737B2adE40ea527d41ceEedc166f',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.58',
    version: 3,
  },
  {
    sousId: 281,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.deprecated_MIX,
    contractAddress: '0x0F96E19Bdc787e767BA1e8F1aDD0f62cbdad87C8',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '63.136',
    version: 3,
  },
  {
    sousId: 280,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.metis,
    contractAddress: '0xC0A94bFF88EdCae7D5d79294C0e9954Ed75CBCb7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01331',
    version: 3,
  },
  {
    sousId: 279,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.gal,
    contractAddress: '0xa5D57C5dca083a7051797920c78fb2b19564176B',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.09645',
    version: 3,
  },
  {
    sousId: 278,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.deprecated_rpg,
    contractAddress: '0xD1c395BCdC2d64ac6544A34A36185483B00530a1',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.06794',
    version: 3,
  },
  {
    sousId: 277,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ankr,
    contractAddress: '0xc581345e1648CcE154978eA80bF8A584EC8aFDe0',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '4.6296',
    version: 3,
  },
  {
    sousId: 276,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ceek,
    contractAddress: '0xED53944b1c0cEecDe1a413fDb4D0496e1a08ab58',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.8078',
    version: 3,
  },
  {
    sousId: 275,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.tinc,
    contractAddress: '0x9593462fF51A14633b243Ba3d054A8183d057A02',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4677',
    version: 3,
  },
  {
    sousId: 274,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pex,
    contractAddress: '0x641B1F2781B34a493E4308A0A3F1c7E042A9B952',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.3865',
    version: 3,
  },
  {
    sousId: 273,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.gmi,
    contractAddress: '0x0D53E0f2Eb384777442e4EB813d8f5fAcC742206',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '17.939',
    version: 3,
  },
  {
    sousId: 272,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.froyo,
    contractAddress: '0x84e3208578eE7db397a3d584d97Fea107b15bF35',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3.404',
    version: 3,
  },
  {
    sousId: 271,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bsw,
    contractAddress: '0x7092e029E4ce660F9AC081BF6D8a339BE602398b',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.157',
    version: 3,
  },
  {
    sousId: 270,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.duet,
    contractAddress: '0xA581349F26dE887700045F9B7d148775d422fDA2',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4861',
    version: 3,
  },
  {
    sousId: 269,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.gmt,
    contractAddress: '0xe76a31cF974BA5819ce86cB4667a4bf05174bC59',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '4.629',
    version: 3,
  },
  {
    sousId: 268,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xwg,
    contractAddress: '0x6e0272A70075f6782F6842730107E9ABf74C5CC7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '5.106',
    version: 3,
  },
  {
    sousId: 267,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.high,
    contractAddress: '0x60c4998C058BaC8042712B54E7e43b892Ab0B0c4',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.09756',
    version: 3,
  },
  {
    sousId: 266,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.thg,
    contractAddress: '0xD1D03A3D4C27884a8703Cdb78504737C9E9A159e',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.162',
    version: 3,
  },
  {
    sousId: 265,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.era,
    contractAddress: '0x260F95f5b7FD8eda720ED9d0829164dE35B048ab',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.608',
    version: 3,
  },
  {
    sousId: 264,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.btt,
    contractAddress: '0x346a1b672C5Cbb6AE21715428f77A0049B29B332',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '157829',
  },
  {
    sousId: 263,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ach,
    contractAddress: '0xcD1Be742b04DB005e2C445A11BDe6d13DD9dd454',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '7.502',
  },
  {
    sousId: 261,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sfund,
    contractAddress: '0x80762101bd79D6e7A175E9678d05c7f815b8D7d7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.06481',
  },
  {
    sousId: 260,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.raca,
    contractAddress: '0xAaF43935a526DF88AB57FC69b1d80a8d35e1De82',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '204.2',
  },
  {
    sousId: 259,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ertha,
    contractAddress: '0x921Ea7e12A66025F2BD287eDbff6dc5cEABd6477',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.585',
  },
  {
    sousId: 258,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.fuse,
    contractAddress: '0xeAd7b8fc5F2E5672FAe9dCf14E902287F35CB169',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.19',
  },
  {
    sousId: 257,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.froyo,
    contractAddress: '0x1c9E3972fdBa29b40954Bb7594Da6611998F8830',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.893',
  },
  {
    sousId: 256,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.aog,
    contractAddress: '0xa34832efe74133763A85060a64103542031B0A7E',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.6435',
  },
  {
    sousId: 255,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.apx,
    contractAddress: '0x92c07c325cE7b340Da2591F5e9CbB1F5Bab73FCF',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3.035',
  },
  {
    sousId: 254,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bcoin,
    contractAddress: '0x25ca61796D786014FfE15E42aC11C7721d46E120',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1493',
  },
  {
    sousId: 253,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bcoin,
    contractAddress: '0xad8F6A9d58012DCa2303226B287E80e5fE27eff0',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1493',
  },
  {
    sousId: 252,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.insur,
    contractAddress: '0x1A777aE604CfBC265807A46Db2d228d4CC84E09D',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.3281',
  },
  {
    sousId: 251,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.gm,
    contractAddress: '0x09e727c83a75fFdB729280639eDBf947dB76EeB7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '7893',
  },
  {
    sousId: 250,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.woop,
    contractAddress: '0x2718D56aE2b8F08B3076A409bBF729542233E451',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.226',
  },
  {
    sousId: 249,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.high,
    contractAddress: '0x2461ea28907A2028b2bCa40040396F64B4141004',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.02679',
  },
  {
    sousId: 247,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dpt,
    contractAddress: '0x1c0C7F3B07a42efb4e15679a9ed7e70B2d7Cc157',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '4.62962963',
  },
  {
    sousId: 246,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.thg,
    contractAddress: '0x56Bfb98EBEF4344dF2d88c6b80694Cba5EfC56c8',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.03761',
  },
  {
    sousId: 245,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.idia,
    contractAddress: '0x07984aBb7489CD436d27875c07Eb532d4116795a',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1262',
  },
  {
    sousId: 244,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xcv,
    contractAddress: '0xF1fA41f593547E406a203b681df18acCC3971A43',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.7413',
  },
  {
    sousId: 243,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.nabox,
    contractAddress: '0x13A40BFab005D9284f8938FBb70Bf39982580e4D',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1271',
  },
  {
    sousId: 242,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.santos,
    contractAddress: '0x0914b2d9D4DD7043893DEF53ecFC0F1179F87d5c',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0578',
  },
  {
    sousId: 241,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.quidd,
    contractAddress: '0xD97Ee2bFE79A4d4ab388553411c462fbb536A88C',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.07471',
  },
  {
    sousId: 240,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.zoo,
    contractAddress: '0x2EfE8772EB97B74be742d578A654AB6C95bF18db',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4516',
  },
  {
    sousId: 239,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sfund,
    contractAddress: '0x7F103689cabe17C2F70DA6faa298045d72a943b8',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0311',
  },
  {
    sousId: 238,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.qi,
    contractAddress: '0xbd52ef04DB1ad1c68A8FA24Fa71f2188978ba617',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.736',
  },
  {
    sousId: 237,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.kart,
    contractAddress: '0x73bB10B89091f15e8FeD4d6e9EBa6415df6acb21',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1897',
  },
  {
    sousId: 236,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.porto,
    contractAddress: '0xdD52FAB121376432DBCBb47592742F9d86CF8952',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0578',
  },
  {
    sousId: 235,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dvi,
    contractAddress: '0x2b8751B7141Efa7a9917f9C6fea2CEA071af5eE7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.2516',
  },
  {
    sousId: 234,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.jgn,
    contractAddress: '0xfDFb4DbE94916F9f55dBC2c14Ea8B3e386eCD9F9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5233',
  },
  {
    sousId: 232,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xwg,
    contractAddress: '0x79f5f7DDADeFa0A9e850DFFC4fBa77e5172Fe701',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.3379',
  },
  {
    sousId: 231,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dar,
    contractAddress: '0x9b861A078B2583373A7a3EEf815bE1A39125Ae08',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.3148',
  },
  {
    sousId: 230,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.fina,
    contractAddress: '0xa35caA9509a2337E22C54C929146D5F7f6515794',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.06944',
  },
  {
    sousId: 229,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bmon,
    contractAddress: '0x6e63B2B96c77532ea7ec2B3D3BFA9C8e1d383f3C',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.0254',
  },
  {
    sousId: 228,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dkt,
    contractAddress: '0xFef4B7a0194159d89717Efa592384d42B28D3926',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.09838',
  },
  {
    sousId: 227,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ramp,
    contractAddress: '0x2D26e4b9a5F19eD5BB7AF221DC02432D31DEB4dA',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.2152',
  },
  {
    sousId: 226,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.lazio,
    contractAddress: '0xd008416C2C9cF23843BD179AA3cefedB4c8D1607',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0694',
  },
  {
    sousId: 225,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sps,
    contractAddress: '0xD9B63Bb6C62FE2E9a641699A91e680994b8b0081',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.40046',
  },
  {
    sousId: 224,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.mcb,
    contractAddress: '0xCc2D359c3a99d9cfe8e6F31230142efF1C828e6D',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00706',
  },
  {
    sousId: 223,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.deprecated_rpg,
    contractAddress: '0x65C0940C50A3C98AEEc95a115Ae62E9804588713',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01863',
  },
  {
    sousId: 222,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.beta,
    contractAddress: '0x6f660C58723922c6f866a058199FF4881019B4B5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.7361',
  },
  {
    sousId: 233,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.eternal,
    contractAddress: '0xc28c400F2B675b25894FA632205ddec71E432288',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.002893',
  },
  {
    sousId: 221,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.nft,
    contractAddress: '0x8d018823d13C56D62fFB795151a9e629C21E047b',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '173727',
  },
  {
    sousId: 220,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.chess,
    contractAddress: '0x4D1Ec426d0d7fb6bF344Dd372d0502EDD71c8d88',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0861',
  },
  {
    sousId: 219,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.tlos,
    contractAddress: '0xCB41a72067c227D6Ed7bc7CFAcd13eCe47Dfe5E9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.3616',
  },
  {
    sousId: 218,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.stephero,
    contractAddress: '0xcECBa456Fefe5b18D43DF23419e7AB755b436655',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1851',
  },
  {
    sousId: 217,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bp,
    contractAddress: '0x8eD7aCF12b08274D5CdAF03d43D0E54bCbDD487e',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.217',
  },
  {
    sousId: 216,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.cvp,
    contractAddress: '0xC4b15117BC0be030c20754FF36197641477af5d1',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.14',
  },
  {
    sousId: 215,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.qbt,
    contractAddress: '0xB72dEf58D0832f747d6B7197471Fe20AeA7EB463',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.412',
  },
  {
    sousId: 214,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.naos,
    contractAddress: '0xb38b78529bCc895dA16CE2978D6cD6C56e8CfFC3',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1736',
  },
  {
    sousId: 213,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pha,
    contractAddress: '0x2E101b5F7f910F2609e5AcE5f43bD274b1DE09AA',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.28',
  },
  {
    sousId: 212,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bel,
    contractAddress: '0x52733Ad7b4D09BF613b0389045e33E2F287afa04',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.101',
  },
  {
    sousId: 211,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ramp,
    contractAddress: '0x401B9B97bDbC3197C1adFaB9652dC78040bd1E13',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.9837',
  },
  {
    sousId: 210,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pots,
    contractAddress: '0xBeDb490970204cb3CC7B0fea94463BeD67d5364D',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0868',
  },
  {
    sousId: 209,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.deprecated_tusd,
    contractAddress: '0xb6e510AE2Da1AB4E350f837c70823ab75091780e',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5787',
  },
  {
    sousId: 208,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.revv,
    contractAddress: '0x8aA5B2C67852ED5334c8A7F0b5eB0eF975106793',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.331',
  },
  {
    sousId: 207,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bttold,
    contractAddress: '0x3b804460C3C62f0F565aF593984159f13B1AC976',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '263',
  },
  {
    sousId: 206,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.skill,
    contractAddress: '0x455F4d4Cc4d6CA15441a93c631E82aAF338ad843',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00289',
  },
  {
    sousId: 205,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.win,
    contractAddress: '0xDe4AEf42Bb27a2cb45c746aCDe4e4D8aB711D27C',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1815.39',
  },
  {
    sousId: 204,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.if,
    contractAddress: '0x57d3524888Ded4085D9124A422F13b27C8a43DE7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.02835',
  },
  {
    sousId: 203,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sps,
    contractAddress: '0xB56299d8FBF46C509014b103a164ad1fC65EA222',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '12.86',
  },
  {
    sousId: 202,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.c98,
    contractAddress: '0x5e49531BA07bE577323e55666D46C6217164119E',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.7361',
  },
  {
    sousId: 201,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.axs,
    contractAddress: '0xBB472601B3CB32723d0755094BA80B73F67f2AF3',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00744',
  },
  {
    sousId: 200,
    stakingToken: bscTokens.axs,
    earningToken: bscTokens.cake,
    contractAddress: '0x583A36816F3b8401C4fdf682203E0caDA6997740',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00992',
  },
  {
    sousId: 199,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pmon,
    contractAddress: '0x28050E8F024E05F9DdBeF5f60dD49F536DBA0cF0',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.02696',
  },
  {
    sousId: 198,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.trx,
    contractAddress: '0xb2B62f88AB82ed0bB4aB4dA60D9Dc9ACF9e816e5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '9.6643',
  },
  {
    sousId: 197,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.chess,
    contractAddress: '0xD1812e7E28C39e78727592de030fC0e7c366D61a',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4286',
  },
  {
    sousId: 196,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.titan,
    contractAddress: '0x97058CF9B36c9Ef1622485ceF22E72d6fEA32a36',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.08912',
  },
  {
    sousId: 195,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.harmony,
    contractAddress: '0xe595456846155E23B24cc9cbEe910ee97027DB6d',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3.0092',
  },
  {
    sousId: 194,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.mask,
    contractAddress: '0xAE611C6D4D3cA2CeE44cd34EB7aAc29D5A387fcf',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.06423',
  },
  {
    sousId: 193,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dvi,
    contractAddress: '0x135827eaF9746573C0B013F18EE12f138B9b0384',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.7233',
  },
  {
    sousId: 192,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.gum,
    contractAddress: '0x09b8a5f51c9e245402057851ADA274174Fa00E2A',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.67129',
  },
  {
    sousId: 191,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.adx,
    contractAddress: '0x53A2D1db049b5271c6b6dB020dBa0e8A7C4Eb90d',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.7523',
  },
  {
    sousId: 190,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.suter,
    contractAddress: '0x4dA8Da81647ee0Aa7350e9959f3e4771eB753Da0',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '69.9074',
  },
  {
    sousId: 189,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bscpad,
    contractAddress: '0x0446b8F8474C590d2249a4ACDD6Eedbc2e004bca',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.3877',
  },
  {
    sousId: 188,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.rabbit,
    contractAddress: '0x391240A007Bfd8A59bA74978D691219a76c64c5C',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3.993',
  },
  {
    sousId: 187,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.waultx,
    contractAddress: '0x017DEa5C58c2Bcf57FA73945073dF7AD4052a71C',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '7.9108',
  },
  {
    sousId: 186,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.wex,
    contractAddress: '0x6Bd94783caCef3fb7eAa9284f1631c464479829f',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '11.574',
  },
  {
    sousId: 185,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.form,
    contractAddress: '0x7c71723fB1F9Cfb250B702cfc4eBd5D9Ab2E83d9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.4467',
  },
  {
    sousId: 184,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.orbs,
    contractAddress: '0x9C8813d7D0A61d30610a7A5FdEF9109e196a3D77',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3.8946',
  },
  {
    sousId: 183,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.$dg,
    contractAddress: '0xa07a91DA6D10173f33c294803684bCeEde325957',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00135',
  },
  {
    sousId: 182,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.woo,
    contractAddress: '0x88c321D444C88acF3e747dC90F20421b97648903',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.3854',
  },
  {
    sousId: 181,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.woo,
    contractAddress: '0x3C7234c496d76133b48bD6A342E7aEa4f8d87fC8',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0003854',
  },
  {
    sousId: 180,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.htb,
    contractAddress: '0x64473c33c360f315cAb38674F1633505d1d8dcb2',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '5.2083',
  },
  {
    sousId: 179,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.jgn,
    contractAddress: '0x5cC7a19a50bE2A6b2540EbCd55Bd728E732e59C3',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1001',
  },
  {
    sousId: 178,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dft,
    contractAddress: '0x2666E2494E742301fFC8026e476Acc1710a775Ed',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.7361',
  },
  {
    sousId: 177,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hai,
    contractAddress: '0x6ac2213F09A404c86AFf506Aa51B6a5BF1F6e24E',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.3078',
  },
  {
    sousId: 176,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.o3,
    contractAddress: '0x35BD47263f7E57368Df76339903C53bAa99076e1',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.107638',
  },
  {
    sousId: 175,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ampl,
    contractAddress: '0x62dEc3A560D2e8A84D30752bA454f97b26757877',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.398',
  },
  {
    sousId: 174,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.oddz,
    contractAddress: '0x44D1F81e80E43e935d66d65874354eF91E5E49f6',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4843',
  },
  {
    sousId: 173,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bondly,
    contractAddress: '0x4Ea43FCE546975aAe120C9eECeB172500BE4a02b',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.6741',
  },
  {
    sousId: 172,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.marsh,
    contractAddress: '0x567fd708e788e51b68666b9310Ee9dF163D60fAE',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1108',
  },
  {
    sousId: 171,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.mbox,
    contractAddress: '0x36F9452083fC9bC469a31e7966B873F402292433',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4803',
  },
  {
    sousId: 170,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ata,
    contractAddress: '0xC612680457751D0D01B5d901AD08132a3B001900',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.7361',
  },
  {
    sousId: 169,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.mx,
    contractAddress: '0x336bcd59f2B6Eb7221a99F7A50fd03c6bf9A306b',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.27777',
  },
  {
    sousId: 168,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bcfx,
    contractAddress: '0x2b3974DDa76B2d408B7D680a27fbb0393E3CF0e1',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.6944',
  },
  {
    sousId: 167,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.qkc,
    contractAddress: '0xFa67f97eEEE6De55d179eCAbBfE701F27D9A1Ed9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '15.9143',
  },
  {
    sousId: 166,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ktn,
    contractAddress: '0x48852322a185DC5Fc733ff8C8D7c6DCBd2B3b2A2',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01215',
  },
  {
    sousId: 165,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.mtrg,
    contractAddress: '0xf4D0F71698f58f221911515781B05E808a8635cB',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.05613',
  },
  {
    sousId: 164,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.deprecated_tusd,
    contractAddress: '0x9DCeB1d92f7e0361d0766f3D98482424DF857654',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5787',
  },
  {
    sousId: 163,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.vrt,
    contractAddress: '0xB77F1425eC3A7C78b1A1E892f72332C8b5e8FFcb',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '40.5092',
  },
  {
    sousId: 162,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.kalm,
    contractAddress: '0xB9FF4dA0954B300542E722097671EaD8cf337C17',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0868',
  },
  {
    sousId: 161,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ez,
    contractAddress: '0xB19395702460261e51Edf7A7B130109c64f13AF9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01736',
  },
  {
    sousId: 160,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.kalm,
    contractAddress: '0x6E113ecB9Ff2d271140f124C2CC5b5e4B5700c9f',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00868',
  },
  {
    sousId: 159,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.popen,
    contractAddress: '0x7Baf1763CE5d0Da8c9d85927F08a8be9c481ce50',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.19097',
  },
  {
    sousId: 158,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.lien,
    contractAddress: '0x2b8d6C9C62bFc1BeD84724165D3000e61D332caB',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.023148',
  },
  {
    sousId: 157,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.well,
    contractAddress: '0x8a06ff2748eDCBa3FB4e44a6bfDA4e46769e557b',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.2025',
  },
  {
    sousId: 156,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.deri,
    contractAddress: '0x3eba95F5493349bbe0CaD33EAAe05DC6a7E26b90',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.6087',
  },
  {
    sousId: 155,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.chr,
    contractAddress: '0x593EDbD14A5B7eEc828336ACCCA9C16CC12F04be',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.655',
  },
  {
    sousId: 154,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.cyc,
    contractAddress: '0xD714738837944C3c592477249E8edB724A76e068',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00015914',
  },
  {
    sousId: 153,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xend,
    contractAddress: '0x8EA9f2482B2f7b12744a831F81F8d08714adc093',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.31828',
  },
  {
    sousId: 152,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hget,
    contractAddress: '0x8E8125f871eb5bA9D55361365F5391Ab437f9acc',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.03553',
  },
  {
    sousId: 151,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hotcross,
    contractAddress: '0x0E09205E993f78cd5B3A5df355Ae98eE7D0b5834',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3.4722',
  },
  {
    sousId: 150,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.rfox,
    contractAddress: '0xF9F00d41b1f4b3c531ff750a9b986C1a530F33d9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.7361',
  },
  {
    sousId: 149,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.wmass,
    contractAddress: '0x4Af531EcD50167a9402Ce921ee6436dd4cFC04FD',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.24942',
  },
  {
    sousId: 148,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ubxt,
    contractAddress: '0x9B4BaC2d8F69853Aa29cb45478C77FC54532aC22',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.4074',
  },
  {
    sousId: 147,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.btr,
    contractAddress: '0x20EE70a07ae1B475cB150dEC27930D97915726Ea',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.8935',
  },
  {
    sousId: 146,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.τdoge,
    contractAddress: '0x017556dFFb8C6a52Fd7F4788aDF6fb339309C81B',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.868',
  },
  {
    sousId: 145,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pmon,
    contractAddress: '0xDaa711ecf2Ac0BFf5C82fCEEAE96d0008791cC49',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01261',
  },
  {
    sousId: 144,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.one,
    contractAddress: '0x74AF842ecD0B6588ADd455A47aa21ED9bA794108',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '57.87',
  },
  {
    sousId: 143,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.fine,
    contractAddress: '0x42D41749d6e9a1c5b47e27F690D4531f181B2159',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1342',
  },
  {
    sousId: 142,
    stakingToken: bscTokens.doge,
    earningToken: bscTokens.cake,
    contractAddress: '0xBEbD44824631b55991Fa5f2bf5c7a4ec96ff805B',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01388',
  },
  {
    sousId: 141,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bmxx,
    contractAddress: '0x55131f330c886E3f0CaE389CeDb23766aC9aA3ED',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01331',
  },
  {
    sousId: 140,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.oin,
    contractAddress: '0x01453A74a94687Fa3f99b80762435855a13664f4',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.3472',
  },
  {
    sousId: 139,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hyfi,
    contractAddress: '0x0032CeB978FE5FC8A5D5D6f5ADfc005e76397E29',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.8935',
  },
  {
    sousId: 138,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.kun,
    contractAddress: '0x439B46D467402CEbC1a2FA05038B5b696B1f4417',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.23148',
  },
  {
    sousId: 137,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.math,
    contractAddress: '0x377AE5f933AA4CfA41fA03E2CAE8a2BEFCCF53B2',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.15798',
  },
  {
    sousId: 136,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ust,
    contractAddress: '0xce3EbaC3F549ebf1A174A6aC3b390c179422B5f6',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.17361',
  },
  {
    sousId: 135,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.win,
    contractAddress: '0xD26DEc254C699935c286CD90E9841DCABF1aF72d',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '462.96',
  },
  {
    sousId: 134,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.trx,
    contractAddress: '0x93E2867D9b74341C2D19101b7Fbb81d6063CcA4d',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '4.0509',
  },
  {
    sousId: 133,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bttold,
    contractAddress: '0x3b644e44033cFF70BD6b771904225f3dd69DFb6d',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '79.86',
  },
  {
    sousId: 132,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.lmt,
    contractAddress: '0x0A687D7b951348D681f7Ed5Eea84C0Ba7b9566dC',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.66145',
  },
  {
    sousId: 131,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pnt,
    contractAddress: '0x417df1c0e6A498eB1F2247F99032A01D4faFe922',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.09548',
  },
  {
    sousId: 130,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xmark,
    contractAddress: '0xDC8943d806F9Dd64312D155284ABF780455fD345',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0298',
  },
  {
    sousId: 129,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ramp,
    contractAddress: '0xa90a894e5Bc20aB2bE46c7E033A38f8B8eaa771A',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4861',
    isFinished: true,
  },
  {
    sousId: 128,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hakka,
    contractAddress: '0x34ac807E34e534Fe426Da1E11F816422774AAe1C',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3.4722',
  },
  {
    sousId: 127,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pbtc,
    contractAddress: '0x31FA2F516b77c4273168b284AC6D9def5AA6DaFb',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0000031',
  },
  {
    sousId: 126,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.lien,
    contractAddress: '0x7112f8988f075C7784666ab071927AE4109a8076',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.003472',
  },
  {
    sousId: 125,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bbadger,
    contractAddress: '0x126dfBcef85c5Bf335F8BE99CA4006037f417892',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00265278',
  },
  {
    sousId: 124,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bdigg,
    contractAddress: '0x4F0AD2332B1F9983E8F63Cbee617523Bb7de5031',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00000403',
  },
  {
    sousId: 123,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bondly,
    contractAddress: '0x9483ca44324dE06802576866b9D296f7614f45Ac',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4629',
  },
  {
    sousId: 122,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xed,
    contractAddress: '0x72ceec6E2A142678E703aB0710DE78bc819F4cE0',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.3472',
  },
  {
    sousId: 121,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.cgg,
    contractAddress: '0x1C6eD21D3313822ae73ed0d94811FfbBE543f341',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.10918',
  },
  {
    sousId: 120,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.perl,
    contractAddress: '0x1aC0d0333640F57327c83053C581340ebC829E30',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.1574',
  },
  {
    sousId: 119,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.arpa,
    contractAddress: '0xC707e5589aeb1dC117B0BB5A3622362F1812D4fc',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.949',
  },
  {
    sousId: 118,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.oddz,
    contractAddress: '0x22106cdCF9787969e1672d8E6A9C03A889CdA9c5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.11284',
  },
  {
    sousId: 117,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dexe,
    contractAddress: '0x999B86E8BBA3D4F05afB8155963999db70aFA97F',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.009837',
    isFinished: true,
  },
  {
    sousId: 116,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dfd,
    contractAddress: '0xAF3EfE5fCEeBc603Eada6A2b0172be11f7405102',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.46296',
  },
  {
    sousId: 115,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.alpaca,
    contractAddress: '0xF73FdEB26a8C7a4Abf3809d3DB11a06ba5c13D0e',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.22743',
  },
  {
    sousId: 114,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.τbtc,
    contractAddress: '0xAaC7171afC93f4b75e1268d208040B152ac65E32',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00000608',
  },
  {
    sousId: 113,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.τbtc,
    contractAddress: '0x2c6017269B4324D016ca5d8e3267368652c18905',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00000608',
    isFinished: true,
  },
  {
    sousId: 112,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.swingby,
    contractAddress: '0x675434C68F2672c983e36cF10Ed13a4014720B79',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.21527',
  },
  {
    sousId: 111,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xed,
    contractAddress: '0x05d6C2d1d687eACfb5E6440d5a3511E91F2201A8',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.3472',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 110,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hakka,
    contractAddress: '0xD623a32da4A632Ce01766C317D07Cb2CAD56949B',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3.4722',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 109,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.cgg,
    contractAddress: '0xdF75f38DBC98F9F26377414e567aBcb8D57CCa33',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.10918',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 108,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.mix,
    contractAddress: '0xce64A930884B2c68CD93FC1C7c7Cdc221D427692',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.3721',
  },
  {
    sousId: 107,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.suter,
    contractAddress: '0xc1E70edd0141c454b834Deac7ddDeA413424aEf9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '15.3356',
  },
  {
    sousId: 106,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.broobee,
    contractAddress: '0x189d8228CdfDc404Bd9e5bD65ff958cb5fd8855c',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '14.4675',
  },
  {
    sousId: 105,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hzn,
    contractAddress: '0x0196c582216e2463f052E2B07Ef8667Bec9Fb17a',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.6944',
  },
  {
    sousId: 104,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.alpa,
    contractAddress: '0x8f84106286c9c8A42bc3555C835E6e2090684ab7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.23495',
  },
  {
    sousId: 103,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.perl,
    contractAddress: '0xa8d32b31ECB5142f067548Bf0424389eE98FaF26',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.1574',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 102,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.tlm,
    contractAddress: '0xC59aa49aE508050c2dF653E77bE13822fFf02E9A',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '17.361',
  },
  {
    sousId: 101,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.jgn,
    contractAddress: '0x14AeA62384789EDA98f444cCb970F6730877d3F9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.08796',
  },
  {
    sousId: 100,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.eps,
    contractAddress: '0xebb87dF24D65977cbe62538E4B3cFBD5d0308642',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.07716',
  },
  {
    sousId: 99,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.arpa,
    contractAddress: '0x40918EF8efFF4aA061656013a81E0e5A8A702eA7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.949',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 98,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.itam,
    contractAddress: '0x44eC1B26035865D9A7C130fD872670CD7Ebac2bC',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3.096',
  },
  {
    sousId: 97,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bondly,
    contractAddress: '0x1329ad151dE6C441184E32E108401126AE850937',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4629',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 96,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.tko,
    contractAddress: '0x9bbDc92474a7e7321B78dcDA5EF35f4981438760',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.1574',
  },
  {
    sousId: 95,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.apys,
    contractAddress: '0x46530d79b238f809e80313e73715b160c66677aF',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.09953',
  },
  {
    sousId: 94,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hoo,
    contractAddress: '0x47fD853D5baD391899172892F91FAa6d0cd8A2Aa',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.489',
  },
  {
    sousId: 93,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.oddz,
    contractAddress: '0xe25aB6F05BBF6C1be953BF2d7df15B3e01b8e5a5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.11284',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 92,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.easy,
    contractAddress: '0xEB8Fd597921E3Dd37B0F103a2625F855e2C9b9B5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0072338',
  },
  {
    sousId: 91,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.nrv,
    contractAddress: '0xABFd8d1942628124aB971937154f826Bce86DcbC',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.15046',
  },
  {
    sousId: 90,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dego,
    contractAddress: '0x526d3c204255f807C95a99b69596f2f9f72345e5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00405',
  },
  {
    sousId: 89,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.gum,
    contractAddress: '0xAa2082BeE04fc518300ec673F9497ffa6F669dB8',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.08912',
  },
  {
    sousId: 88,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pbtc,
    contractAddress: '0x9096625Bc0d36F5EDa6d44e511641667d89C28f4',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0000031',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 87,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dft,
    contractAddress: '0x78BD4dB48F8983c3C36C8EAFbEF38f6aC7B55285',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.6811',
  },
  {
    sousId: 86,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.swth,
    contractAddress: '0x35418e14F5aA615C4f020eFBa6e01C5DbF15AdD2',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.3148',
  },
  {
    sousId: 85,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.lien,
    contractAddress: '0x3c7cC49a35942fbD3C2ad428a6c22490cd709d03',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.003472',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 84,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.zil,
    contractAddress: '0xF795739737ABcFE0273f4Dced076460fdD024Dd9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.0995',
  },
  {
    sousId: 83,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pcws,
    contractAddress: '0x06FF8960F7F4aE572A3f57FAe77B2882BE94Bf90',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00434',
  },
  {
    sousId: 82,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bbadger,
    contractAddress: '0xe4dD0C50fb314A8B2e84D211546F5B57eDd7c2b9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00265278',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 81,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bdigg,
    contractAddress: '0xb627A7e33Db571bE792B0b69c5C2f5a8160d5500',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00000403',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 80,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.lto,
    contractAddress: '0xadBfFA25594AF8Bc421ecaDF54D057236a99781e',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.2893',
  },
  {
    sousId: 79,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.mir,
    contractAddress: '0x3e31488f08EBcE6F2D8a2AA512aeFa49a3C7dFa7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01273',
  },
  {
    sousId: 78,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.mir,
    contractAddress: '0x453a75908fb5A36D482D5F8fe88ECA836f32eAd5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01273',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 77,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.trade,
    contractAddress: '0x509C99D73FB54b2c20689708b3F824147292D38e',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4484',
  },
  {
    sousId: 76,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dusk,
    contractAddress: '0xF1bd5673Ea4a1C415ec84fa3E402F2F7788E7717',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4629',
  },
  {
    sousId: 75,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bifi,
    contractAddress: '0xB4C68A1C565298834360BbFF1652284275120D47',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00007234',
  },
  {
    sousId: 74,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.txl,
    contractAddress: '0x153e62257F1AAe05d5d253a670Ca7585c8D3F94F',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.434027',
  },
  {
    sousId: 73,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.cos,
    contractAddress: '0xF682D186168b4114ffDbF1291F19429310727151',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '5.787',
  },
  {
    sousId: 72,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bunny,
    contractAddress: '0xaDdAE5f4dB84847ac9d947AED1304A8e7D19f7cA',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00289',
  },
  {
    sousId: 71,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.alice,
    contractAddress: '0x4C32048628D0d32d4D6c52662FB4A92747782B56',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.14467',
  },
  {
    sousId: 70,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.for,
    contractAddress: '0x47642101e8D8578C42765d7AbcFd0bA31868c523',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.8935',
  },
  {
    sousId: 69,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bux,
    contractAddress: '0x07F8217c68ed9b838b0b8B58C19c79bACE746e9A',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5787',
  },
  {
    sousId: 68,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.nuls,
    contractAddress: '0x580DC9bB9260A922E3A4355b9119dB990F09410d',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0868',
  },
  {
    sousId: 67,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.belt,
    contractAddress: '0x6f0037d158eD1AeE395e1c12d21aE8583842F472',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00868',
    isFinished: true,
  },
  {
    sousId: 66,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ramp,
    contractAddress: '0x423382f989C6C289c8D441000e1045e231bd7d90',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4861',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 65,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bfi,
    contractAddress: '0x0A595623b58dFDe6eB468b613C11A7A8E84F09b9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0001157',
  },
  {
    sousId: 64,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dexe,
    contractAddress: '0x9E6dA246d369a41DC44673ce658966cAf487f7b2',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.009837',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 63,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bel,
    contractAddress: '0x2C0f449387b15793B9da27c2d945dBed83ab1B07',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0549',
  },
  {
    sousId: 62,
    stakingToken: bscTokens.tpt,
    earningToken: bscTokens.cake,
    contractAddress: '0x0c3D6892aa3b23811Af3bd1bbeA8b0740E8e4528',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0462',
    isFinished: true,
  },
  {
    sousId: 61,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.tpt,
    contractAddress: '0x75C91844c5383A68b7d3A427A44C32E3ba66Fe45',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3.616',
    isFinished: true,
  },
  {
    sousId: 60,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.watch,
    contractAddress: '0xC58954199E268505fa3D3Cb0A00b7207af8C2D1d',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.3472',
    isFinished: true,
  },
  {
    sousId: 59,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.xmark,
    contractAddress: '0xA5137e08C48167E363Be8Ec42A68f4F54330964E',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0413',
    isFinished: true,
  },
  {
    sousId: 58,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bmxx,
    contractAddress: '0x6F31B87f51654424Ce57E9F8243E27ed13846CDB',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00248',
    isFinished: true,
  },
  {
    sousId: 57,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.iotx,
    contractAddress: '0xCE54BA909d23B9d4BE0Ff0d84e5aE83F0ADD8D9a',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '6.365',
    isFinished: true,
  },
  {
    sousId: 56,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bor,
    contractAddress: '0x3e677dC00668d69c2A7724b9AFA7363e8A56994e',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.000395',
    isFinished: true,
  },
  {
    sousId: 55,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bopen,
    contractAddress: '0x5Ac8406498dC1921735d559CeC271bEd23B294A7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0723',
    isFinished: true,
  },
  {
    sousId: 54,
    stakingToken: bscTokens.sushi,
    earningToken: bscTokens.cake,
    contractAddress: '0xb69b6e390cba1F68442A886bC89E955048DAe7E3',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0367',
    isFinished: true,
  },
  {
    sousId: 53,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.dodo,
    contractAddress: '0xae3001ddb18A6A57BEC2C19D71680437CA87bA1D',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0578',
    isFinished: true,
  },
  {
    sousId: 52,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.swingby,
    contractAddress: '0x02aa767e855b8e80506fb47176202aA58A95315a',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.13',
    isFinished: true,
  },
  {
    sousId: 51,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bry,
    contractAddress: '0x1c736F4FB20C7742Ee83a4099fE92abA61dFca41',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1157',
    isFinished: true,
  },
  {
    sousId: 50,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.zee,
    contractAddress: '0x02861B607a5E87daf3FD6ec19DFB715F1b371379',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1736',
    isFinished: true,
  },
  {
    sousId: 49,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.swgb,
    contractAddress: '0x73e4E8d010289267dEe3d1Fc48974B60363963CE',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.899',
    isFinished: true,
  },
  {
    sousId: 48,
    stakingToken: bscTokens.comp,
    earningToken: bscTokens.cake,
    contractAddress: '0xE0565fBb109A3f3f8097D8A9D931277bfd795072',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.055',
    isFinished: true,
  },
  {
    sousId: 47,
    stakingToken: bscTokens.comp,
    earningToken: bscTokens.cake,
    contractAddress: '0xc3693e3cbc3514d5d07EA5b27A721F184F617900',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.55',
    isFinished: true,
  },
  {
    sousId: 46,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sfp,
    contractAddress: '0x2B02d43967765b18E31a9621da640588f3550EFD',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.6',
    isFinished: true,
  },
  {
    sousId: 45,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.lina,
    contractAddress: '0x212bb602418C399c29D52C55100fD6bBa12bea05',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.983',
    isFinished: true,
  },
  {
    sousId: 44,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.lina,
    contractAddress: '0x04aE8ca68A116278026fB721c06dCe709eD7013C',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0983',
    isFinished: true,
  },
  {
    sousId: 43,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.lit,
    contractAddress: '0x1714bAAE9DD4738CDEA07756427FA8d4F08D9479',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.231',
    isFinished: true,
  },
  {
    sousId: 42,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hget,
    contractAddress: '0xcCD0b93cC6ce3dC6dFaA9DB68f70e5C8455aC5bd',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0138',
    isFinished: true,
  },
  {
    sousId: 41,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bdo,
    contractAddress: '0x9cB24e9460351bC51d4066BC6AEd1F3809b02B78',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.075',
    isFinished: true,
  },
  {
    sousId: 40,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.egld,
    contractAddress: '0x2dcf4cDFf4Dd954683Fe0a6123077f8a025b66cF',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.001215',
    isFinished: true,
  },
  {
    sousId: 39,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ust,
    contractAddress: '0x6EFa207ACdE6e1caB77c1322CbdE9628929ba88F',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1157',
    isFinished: true,
  },
  {
    sousId: 38,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.wsote,
    contractAddress: '0xD0b738eC507571176D40f28bd56a0120E375f73a',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.23',
    isFinished: true,
  },
  {
    sousId: 37,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.front,
    contractAddress: '0xf7a31366732F08E8e6B88519dC3E827e04616Fc9',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.2546',
    isFinished: true,
  },
  {
    sousId: 36,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.helmet,
    contractAddress: '0x9F23658D5f4CEd69282395089B0f8E4dB85C6e79',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.81',
    isFinished: true,
  },
  {
    sousId: 35,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.btcst,
    contractAddress: '0xB6fd2724cc9c90DD31DA35DbDf0300009dceF97d',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.011574',
    isFinished: true,
  },
  {
    sousId: 34,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bscx,
    contractAddress: '0x108BFE84Ca8BCe0741998cb0F60d313823cEC143',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.17361',
    isFinished: true,
  },
  {
    sousId: 33,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ten,
    contractAddress: '0x4A26b082B432B060B1b00A84eE4E823F04a6f69a',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.05787',
    isFinished: true,
  },
  {
    sousId: 32,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.balbt,
    contractAddress: '0x3cc08B7C6A31739CfEd9d8d38b484FDb245C79c8',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4166',
    isFinished: true,
  },
  {
    sousId: 31,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.asr,
    contractAddress: '0xd18E1AEb349ef0a6727eCe54597D98D263e05CAB',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01',
    isFinished: true,
  },
  {
    sousId: 30,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.atm,
    contractAddress: '0x68C7d180bD8F7086D91E65A422c59514e4aFD638',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01',
    isFinished: true,
  },
  {
    sousId: 29,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.og,
    contractAddress: '0xbE65d7e42E05aD2c4ad28769dc9c5b4b6EAff2C7',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01',
    isFinished: true,
  },
  {
    sousId: 28,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.reef,
    contractAddress: '0x1500fA1AFBFE4f4277ED0345cdf12b2C9cA7e139',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '115.74',
    isFinished: true,
  },
  {
    sousId: 27,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ditto,
    contractAddress: '0x624ef5C2C6080Af188AF96ee5B3160Bb28bb3E02',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01157',
  },
  {
    sousId: 26,
    stakingToken: bscTokens.twt,
    earningToken: bscTokens.cake,
    contractAddress: '0x0554a5D083Abf2f056ae3F6029e1714B9A655174',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.248',
    isFinished: true,
  },
  {
    sousId: 24,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.juv,
    contractAddress: '0x543467B17cA5De50c8BF7285107A36785Ab57E56',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.02',
    isFinished: true,
  },
  {
    sousId: 25,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.psg,
    contractAddress: '0x65aFEAFaec49F23159e897EFBDCe19D94A86A1B6',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.02',
    isFinished: true,
  },
  {
    sousId: 21,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.vai,
    contractAddress: '0x1AD34D8d4D79ddE88c9B6b8490F8fC67831f2CAe',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.104',
    isFinished: true,
  },
  {
    sousId: 20,
    stakingToken: bscTokens.bnb,
    earningToken: bscTokens.cake,
    contractAddress: '0x555Ea72d7347E82C614C16f005fA91cAf06DCB5a',
    poolCategory: PoolCategory.BINANCE,
    tokenPerBlock: '0.5',
    isFinished: true,
  },
  {
    sousId: 19,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.bnb,
    contractAddress: '0x326D754c64329aD7cb35744770D56D0E1f3B3124',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0041',
    isFinished: true,
  },
  {
    sousId: 18,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.blink,
    contractAddress: '0x42Afc29b2dEa792974d1e9420696870f1Ca6d18b',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '23.14',
    isFinished: true,
  },
  {
    sousId: 17,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.blink,
    contractAddress: '0xBb2B66a2c7C2fFFB06EA60BeaD69741b3f5BF831',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '23.14',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 16,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.unfi,
    contractAddress: '0xFb1088Dae0f03C5123587d2babb3F307831E6367',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.02893',
    isFinished: true,
  },
  {
    sousId: 15,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.twt,
    contractAddress: '0x9c4EBADa591FFeC4124A7785CAbCfb7068fED2fb',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '5',
    isFinished: true,
  },
  {
    sousId: 14,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.hard,
    contractAddress: '0x90F995b9d46b32c4a1908A8c6D0122e392B3Be97',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.346',
    isFinished: true,
  },
  {
    sousId: 13,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.broobee,
    contractAddress: '0xdc8c45b7F3747Ca9CaAEB3fa5e0b5FCE9430646b',
    poolCategory: PoolCategory.COMMUNITY,
    tokenPerBlock: '12.5',
    isFinished: true,
  },
  {
    sousId: 12,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.stax,
    contractAddress: '0xFF02241a2A1d2a7088A344309400E9fE74772815',
    poolCategory: PoolCategory.COMMUNITY,
    tokenPerBlock: '0.2',
    isFinished: true,
  },
  {
    sousId: 11,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.nar,
    contractAddress: '0xDc938BA1967b06d666dA79A7B1E31a8697D1565E',
    poolCategory: PoolCategory.COMMUNITY,
    tokenPerBlock: '1',
    isFinished: true,
  },
  {
    sousId: 10,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.nya,
    contractAddress: '0x07a0A5B67136d40F4d7d95Bc8e0583bafD7A81b9',
    poolCategory: PoolCategory.COMMUNITY,
    tokenPerBlock: '10',
    isFinished: true,
  },
  {
    sousId: 9,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ctk,
    contractAddress: '0x21A9A53936E812Da06B7623802DEc9A1f94ED23a',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5',
    isFinished: true,
  },
  {
    sousId: 8,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.twt,
    contractAddress: '0xe7f9A439Aa7292719aC817798DDd1c4D35934aAF',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '20',
    isFinished: true,
  },
  {
    sousId: 7,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.inj,
    contractAddress: '0xcec2671C81a0Ecf7F8Ee796EFa6DBDc5Cb062693',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.25',
    isFinished: true,
  },
  {
    sousId: 248,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ccar,
    contractAddress: '0x9e31aef040941E67356519f44bcA07c5f82215e5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.6093',
  },
  {
    sousId: 262,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.ach,
    contractAddress: '0xD5668e936B951292Ddf8c84553CC58F85948F816',
    poolCategory: PoolCategory.CORE,

    enableEmergencyWithdraw: true,
    tokenPerBlock: '7.502',
  },
].map((p) => ({
  ...p,
  isFinished: true,
  contractAddress: getAddress(p.contractAddress),
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export const pools: SerializedPool[] = [...livePools, ...finishedPools]
